import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Reactmarkdown from 'react-markdown';

import { Container, Row, Col } from 'react-grid-system';
import Layout from '../components/Layouts/layout';

const ArticleTemplate = ({ data }) => {
	return (
		<Layout
			header={data.markdownRemark.frontmatter.title}
			breadcrumb={{
				text: 'Backgrounders',
				path: '/news-and-events/backgrounders/'
			}}
		>
			<Container>
				<Row>
					<Col lg={4} md={6}>
						<Img
							style={{ marginTop: `1rem` }}
							fluid={data.markdownRemark.frontmatter.image.childImageSharp.fluid}
						/>
					</Col>

					<Col lg={8} md={6}>
						<Reactmarkdown source={data.markdownRemark.rawMarkdownBody} />
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default ArticleTemplate;

export const query = graphql`
	query BackgrounderTemplate($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			rawMarkdownBody
			frontmatter {
				slug
				title
				image {
					absolutePath
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
			html
		}
	}
`;
